import React from 'react'
import Layout from '../components/layout'
import PanierSkus from '../components/panier-skus'
import Seo from '../components/SEO'

import '../ui/panier.css'


const post = {
    title: "Encore une dernière étape avant de profiter de merveilleuses bombes de bain",
    description: 'Les bombes de bain artisanales sont à 2 clics de chez toi. Une dernière chance pour en profiter ?',
    image: 'https://archimouss.ch/assets/images/logo/big.png'
  }

const Annuler = () => {
    

    return (
        <>
        <Seo title={post.title} description={post.description} image={post.image} index={true}/>
    
            <Layout displayBasket={true} displayIcons={true}>
                <div className='main'>
                    <div className='main-content'>
                        <div className='panier-main'>
                            <div className='panier-content'>
                                <h1>Votre achat a été annulé</h1>
                                <PanierSkus></PanierSkus>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
  )
}

export default Annuler
import React from 'react'

import '../ui/panier-sku-card.css'

const PanierSkuCard = ({sku, incrementItem, decrementItem, removeItem}) => {

  return (
    <>
     
      <div className="panier-sku-card-container">
          <div className="panier-sku-card-container-image">
              <img alt={sku.name} src={sku.images}></img>
          </div>
          <div className='panier-sku-card-container-elements'>
            <div className="panier-sku-card-container-text-remove">
                <button onClick={() => removeItem(sku.sku)} className="accent-button">x</button>
            </div>
            <div className="panier-sku-card-container-text">
                <p className='panier-sku-card-container-text-p panier-sku-card-container-text-p-title'>{sku.name}</p>
                <div className="panier-sku-card-container-text-quantity">
                    <button onClick={() => {
                      
                      if (sku.quantity > 1) {
                        decrementItem(sku.sku)
                      }
                    
                      }} className={sku.quantity > 1 ? "accent-button" : "accent-disabled-button"}>-</button>
                
                    <p>Quantité {sku.quantity}</p>
                    <button onClick={() => incrementItem(sku.sku)} className="accent-button">+</button>
                </div>
                
                <p className='panier-sku-card-container-text-p'>{sku.price} à l'unité</p>
                <p className='panier-sku-card-container-text-p'>Total : {sku.total}</p>
              
            </div>
          </div>
          
      </div>
    </>
    
  )
}

export default PanierSkuCard
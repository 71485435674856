import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useShoppingCart } from 'use-shopping-cart'
import { loadStripe } from '@stripe/stripe-js'
import PanierSkuCard from './panier-sku-card'

import '../ui/panier-skus.css'


const stripePromise = loadStripe(`${process.env.STRIPE_PUBLISHABLE_KEY}`,)


const PanierSkus = () => {

    const { formattedTotalPrice, cartCount, removeItem, cartDetails, incrementItem, decrementItem } = useShoppingCart()

    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState('idle')
    const [isSwitzerland, setIsSwitzerland] = useState(true)

    const isBrowser = () => typeof window !== "undefined"


    function getWindow() {
      if (isBrowser() && window.location.origin) {
        return window.location.origin
      } else {
        return ''
      }
    }

    function changeCountry($event) {
      setIsSwitzerland(!isSwitzerland)
    }
    

    function getCheckoutData(cartDetails) {
        const lineItems = []
        for (const sku in cartDetails)
          lineItems.push({ price: sku, quantity: cartDetails[sku].quantity })
        
        if (isSwitzerland) {
          lineItems.push({ price: process.env.DELIVERY_SWITZERLAND, quantity: 1 })
        } else {
          lineItems.push({ price: process.env.DELIVERY_FRANCE, quantity: 1 })
        }
        
        
      
        const options = {
          mode: 'payment',
          lineItems,
          successUrl:`${getWindow()}/confirmation`,
          cancelUrl: `${getWindow()}/annuler`,
          billingAddressCollection: 'required',
          shippingAddressCollection: {
            allowedCountries: [isSwitzerland ? 'CH' : 'FR'],
          },
          submitType: 'auto',
        }
      
        return options
      }

      async function handleClick(event) {
        event.preventDefault()
    
        if (cartCount > 0) {
         
          setStatus('idle')
          setLoading(true)
     
          try {
  
            const stripe = await stripePromise;
  
            const checkoutData = getCheckoutData(cartDetails)
            const result = await stripe.redirectToCheckout(checkoutData)
  
            if (result?.error) {
              setStatus('redirect-error')
              setLoading(false)
              console.log(result?.error)
            }
  
          } catch (error) {
            setStatus('redirect-error')
            setLoading(false)
            console.log(error)
          }
        } else {
          setStatus('missing-items')
          setLoading(false)
        }
      }



    return (
        <>
        
        {Object.keys(cartDetails).length > 0 ?
        <div className='panier-sku-container-total'>

            <button className='accent-button'
                disabled={loading}
                onClick={handleClick}
                >
                   {loading ? 'Loading...' : 'Commander'} 
            </button>
        
        </div>
        : null }
        <div className="panier-sku-container">
 
            {
                Object.keys(cartDetails).map((sku) => {

                    const newSku = {
                        sku: cartDetails[sku].id,
                        name: cartDetails[sku].name,
                        price: cartDetails[sku].formattedPrice,
                        quantity: cartDetails[sku].quantity,
                        total: cartDetails[sku].formattedValue,
                        images: cartDetails[sku].image,
                    }
                    
                    return <PanierSkuCard key={cartDetails[sku].id} sku={newSku} incrementItem={incrementItem} decrementItem={decrementItem} removeItem={removeItem}/>
    
                })
            }

            {Object.keys(cartDetails).length === 0 ?
            <>
                <p>Aucun article dans le panier</p>
                <br></br>
                <div className='panier-btn-retour'>
                  <Link className="accent-button" alt="retour à la page d'acceuil" to="/">Retour à la page d'acceuil</Link>
                </div>
                
            </> : null}
        </div>
        
        {Object.keys(cartDetails).length > 0 ?
        <>
          <div className='panier-sku-container-total'>
            <p>Livraison en </p>
             <select onChange={changeCountry}>
              <option>Suisse</option>
              <option>France</option>
            </select>
          </div>
          <div className='panier-sku-container-total'>
              <p>
                      Total {formattedTotalPrice} 
              </p>
              <button className='accent-button'
                  disabled={loading}
                  onClick={handleClick}
                  >
                    {loading ? 'Loading...' : 'Commander'} 
              </button>
          
          </div>
        </>
        : null }
        {status === 'redirect-error' && (
            <p>Impossible de préparer le paiement par Stripe</p>
        )}

        </>

    )
}


export default PanierSkus